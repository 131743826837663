<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-row>
        <v-col>
          <div id="lbl-screen-name">
            {{ title }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- 検索項目 -->
    <v-container fluid>
      <v-row class="search-row">
        <v-row>
          <span class="asterisk-spacer">&nbsp;</span>

          <!-- ピッキングNo -->
          <div class="text-label first-search-item" style="float: left">
            <v-text-field
              dense
              outlined
              class="disabled"
              id="get-picking"
              v-model="pickingNo"
              :label="$t('label.lbl_pickingNo')"
            />
          </div>
        </v-row>
      </v-row>
    </v-container>

    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        fixed-header
        :headers="headerItems"
        :items="inputList"
        disable-filtering
        disable-sort
        disable-pagination
        :hide-default-footer="true"
        height="100%"
      >
        <!-- ロット -->
        <template v-slot:[`header.lot`]="{ header }">
          <span>
            {{ header.text }}<br />
            {{
              $t("label.lbl_dueDate") +
              "／" +
              $t("label.lbl_lotSubkey1") +
              "／" +
              $t("label.lbl_lotSubkey2")
            }}
          </span>
        </template>

        <!-- 出荷No -->
        <template v-slot:[`item.shippingNo`]="{ item }">
          <div class="listNo-style">{{ item.shippingNo }}</div>
        </template>

        <!-- 倉庫・ロケーション -->
        <template v-slot:[`item.warehouseLoca`]="{ item }">
          <div v-if="item.location.length > 10">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 150px" v-bind="attrs" v-on="on">
                  {{ item.warehouseName }}
                </div>
                <div class="text-overflow" style="max-width: 150px">
                  {{ item.location }}
                </div>
              </template>
              <div>{{ item.warehouseName }}</div>
              <div>{{ item.location }}</div>
            </v-tooltip>
          </div>
          <div v-else>
            <div>{{ item.warehouseName }}</div>
            <div>{{ item.location }}</div>
          </div>
        </template>
        <!-- * -->

        <!-- 商品コード・品名 -->
        <template v-slot:[`item.productCd`]="{ item }">
          <div
            v-if="
              item.itemCd.length > 10 ||
              item.accessoryProductCd.length > 10 ||
              item.itemName.length > 10
            "
          >
            <div v-if="item.accessoryProductCd">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="text-overflow" style="max-width: 185px">
                    {{ item.itemCd }}<br />
                    {{ item.accessoryProductCd }}<br />
                    {{ item.itemName }}
                  </div>
                </template>
                <div>{{ item.itemCd }}</div>
                <div>{{ item.accessoryProductCd }}</div>
                <div>{{ item.itemName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="text-overflow" style="max-width: 185px">
                    {{ item.itemCd }}<br />
                    {{ item.itemName }}
                  </div>
                </template>
                <div>{{ item.itemCd }}</div>
                <div>{{ item.itemName }}</div>
              </v-tooltip>
            </div>
          </div>
          <div v-else>
            <div v-if="item.accessoryProductCd">
              {{ item.itemCd }}<br />
              {{ item.accessoryProductCd }}<br />
              {{ item.itemName }}
            </div>
            <div v-else>
              {{ item.itemCd }}<br />
              {{ item.itemName }}
            </div>
          </div>
        </template>
        <!-- * -->
        <!-- ロット/S/N-->
        <template v-slot:[`item.lot`]="{ item }">
          <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" v-bind="attrs" v-on="on" style="max-width: 340px">
                  {{ item.lot }}<br />
                  {{ item.dueDate }}
                </div>
              </template>
              <div>{{ item.lot }}</div>
              <div>{{ item.dueDate }}</div>
            </v-tooltip>
          </div>
          <div v-else>
            <div>
              {{ item.lot }}<br />
              {{ item.dueDate }}
            </div>
          </div>
        </template>

        <!-- ゴミ箱 -->
        <template v-slot:[`item.delete`]="{ item }">
          <div
            style="max-width: 100px"
            v-if="item.deleteFlg == '1' || item.processingFlg == '1'"
          ></div>
          <div v-else>
            <v-btn small @click="deleteItem(item)" text>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
        <!-- * -->
      </v-data-table>

      <!-- v-row 3 引当取消ボタン 始まり -->
      <div class="text-popBtn pt-2">
        <v-btn color="primary" class="api-btn" @click="close" style="float: right">
          {{ $t("btn.btn_close") }}
        </v-btn>
        <v-btn
          :disabled="deleteFlg"
          v-if="registRole == 'true'"
          color="primary"
          class="two-left-btn"
          @click="resarvationDelete"
          style="float: right"
          >{{ $t("btn.btn_reservationCancel") }}</v-btn
        >
      </div>
      <!-- v-row 3 ボタン 終わり -->
    </v-container>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="deleteStock"
      :cancelAction="confirmCancel"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :changeFlag="infoDialog.changeFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";

export default {
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
  },
  // 親画面から渡される製品コード
  props: {
    pickingInfo: {},
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // 引当済レコードボタン非活性フラグ
    deleteFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    title: "",
    // 一覧
    locationList: [],
    // 編集行
    editedIndex: -1,
    // ピッキングNo
    pickingNo: "",
    // 確定ボタン押下権限
    registRole: "true",
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shippingNo",
        width: "17%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_warehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "warehouseLoca",
        width: "17%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "17%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "24%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_PIC"),
        value: "pic",
        width: "15%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "quantity",
        width: "8%",
        align: "right",
        sortable: false,
      },
      {
        text: i18n.tc("btn.btn_delete"),
        value: "delete",
        width: "2%",
        align: "center",
        sortable: false,
      },
    ],
    inputList: [],
    // 削除リスト
    deleteList: [],
    // 明細リスト
    detailList: [],
  }),

  methods: {
    /**
     * 確認ダイアログ接続
     */
    resarvationDelete() {
      if (this.deleteList.length !== 0) {
        this.confirmDialog.firstPageFlag = false;
        this.confirmDialog.changeFlag = true;
        this.confirmDialog.message = messsageUtil.getMessage("P-STK-002_002_E");
        this.confirmDialog.isOpen = true;
      } else {
        this.infoDialog.message = messsageUtil.getMessage("P-STK-001_001_E");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },

    /**
     * 行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      this.deleteList.push(this.inputList[this.editedIndex]);
      this.inputList.splice(this.editedIndex, 1);
    },

    /**
     * 在庫引当結果取消API（POST）
     */
    deleteStock() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID（追加する）
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_002;
      // 出庫指示リスト
      var bodyList = [];

      for (var i = 0; i < this.deleteList.length; i++) {
        var inputMap = {};

        // 出荷伝票明細SID(取得してきた明細のSID)
        inputMap.outListSubSid = this.deleteList[i].outListSubSid;
        // 出庫指示SID
        inputMap.wareOutInstSid = this.deleteList[i].wareOutInstSid;
        // 更新日時
        inputMap.updateDatetime = dateTimeHelper.convertUTC(this.deleteList[i].updateDatetime);
        bodyList.push(inputMap);
      }

      body.reqIdv.outListsInfo = bodyList;
      body.reqIdv.opeDispName = i18n.tc("label.lbl_pickingListpc");
      // console.debug("deleteStock() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_STOCKALLOCATION_DELETE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("deleteStock() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-EXT-005_002_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 確定キャンセルの場合
    confirmCancel() {
      this.init();
      this.deleteList = [];
    },

    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      // タイトル名
      this.title = this.pickingInfo.pickingNm + i18n.tc("label.lbl_list");
      // 引当結果画面のピッキングNo
      this.pickingNo = this.pickingInfo.pickingNo;

      // 出庫指示リスト取得
      this.getWareOutOrderList();
      // ピッキングリスト出力権限を取得
      this.registRole = sessionStorage.getItem("P-STK-003_REGIST");
    },

    /**
     * 閉じる処理
     */
    close() {
      // this.pickingNo = "";
      this.$emit("update:isOpenDetailDialog", false);
      this.init();
      this.$emit("close");
    },

    /**
     * 出庫指示リスト取得API接続
     */
    getWareOutOrderList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.locationNo = this.location;
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.pickingInfo.suppliersSelected;
      // from日時
      config.params.fromYyyymmddHhmmss = dateTimeHelper.convertUTC(this.pickingInfo.shipInsDate);
      // to日時
      var arr = this.pickingInfo.shipInsDate + " 23:59:59.999";
      config.params.toYyyymmddHhmmss = dateTimeHelper.convertUTC(arr);
      // toSID
      config.params.toSid = this.pickingInfo.toSid;
      // ピッキンググループSID
      config.params.pickingGrSid = this.pickingInfo.pickingNo;
      // console.debug("getWareOutOrderList() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_WAREOUTORDER, config)
          .then((response) => {
            // console.debug("getWareOutOrderList() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.wareOutOrders.forEach((row) => {
                let lotObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  // 出荷No
                  shippingNo: row.outListNo,
                  // 出荷伝票明細SID
                  outListSubSid: row.outListSubSid,
                  // 出庫指示SID
                  wareOutInstSid: row.wareOutInstSid,
                  // 倉庫名
                  warehouseName: row.warehouseName,
                  // ロケ
                  location: row.locationNo,
                  // ユーザー名（担当者）
                  pic: row.userName,
                  // 品名
                  itemName: row.itemName,
                  // 品番
                  itemCd: row.itemCd.substring(0, 50).trim(),
                  //付帯品番
                  accessoryProductCd: row.itemCd.slice(-50).trim(),
                  // ロット、S/N
                  lot: lotObj.lot,
                  // 期限日、付属キー１、付属キー２
                  dueDate: lotObj.dueDate,
                  // 符号付数量
                  quantity: commonUtil.formatToCurrency(Number(row.signStock)),
                  // pickingGrSid
                  pickingGrSid: row.pickingGrSid,
                  // 更新日時
                  updateDatetime: dateTimeHelper.convertUTC2JST(row.updateDatetime),
                  // 削除フラグ
                  deleteFlg: row.deleteFlg,
                  // 流通加工フラグ
                  processingFlg: row.processingFlg,
                });
              });
              this.inputList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).then(() => {
        let isShipAchieve = false;
        for (var i = 0; i < this.inputList.length; i++) {
          // 未出庫の場合
          if (this.inputList[i].deleteFlg == "0") {
            // 引当取消ボタン活性
            this.deleteFlg = false;
            isShipAchieve = true;
            break;
          }
        }
        // 出庫済みの場合
        if (!isShipAchieve) {
          // 引当取消ボタン非活性
          this.deleteFlg = true;
        }
      });
    },

    reload() {},
  },
  computed: {},
  watch: {
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
        this.deleteList = [];
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled {
  pointer-events: none;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper {
  max-height: 604px;
}

#listData.v-data-table--fixed-header ::v-deep thead.v-data-table-header {
  background-color: #effad9;
}
</style>
